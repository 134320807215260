<template>
	<span :class="[`httpstatus-badge`, getHttpStatus(httpstatus)]">{{ httpstatus }}</span>
</template>  

<script>
import '@/assets/scss/vuestudio/ui/_badge_httpstatus.scss';
export default {
	props: {
		httpstatus: {
			type: Number,
			required: true,
		},
	},
	methods: {
		getHttpStatus(status) {

			if (status >= 200 && status < 300) {
				return 'httpstatus-badge-success'
			} else 
			if (status >= 400 && status < 500) {
				return 'httpstatus-badge-warning'
			} else 
			if (status >= 500) {
				return 'httpstatus-badge-error'
			} else {
				return 'httpstatus-badge-default'
			}
    },
  },
};
</script>