<template>
    <div class="webhooklogs-list">
        <ui-table-skeleton v-if="loading" />
        <template v-else>
            <div class="webhooklogs-list row g-2 mb-4">
                <div class="col-md-3">
                    <ui-input
                        id="search"
                        name="search"
                        label="Search logs"
                        type="text"
                        placeholder="Enter keywords"
                        :debounce="500"
                        v-model:value="filters.q"
                    >
                        <template #prepend>
                            <span class="fas fa-search"></span>
                        </template>
                    </ui-input>
                </div>
                <template v-if="_available_filters">
                    <div class="col-md-auto">
                        <date-range-picker
                            ref="picker"
                            v-model="range"
                            opens="right"
                            time-picker
                            time-picker-24-hours
                            show-dropdowns
                            control-container-class="webhooklogs-list__date-range-picker"
                            :linked-calendars="false"
                            @update="changeDate"
                        >
                            <template #input>
                                <em class="fa fa-fw fa-calendar ml-n1"></em>
                                {{ $dayjs(filters.range.startDate).format('D MMMM YYYY') }} -
                                {{ $dayjs(filters.range.endDate).format('D MMMM YYYY') }}
                                <strong class="caret"></strong>
                            </template>
                        </date-range-picker>
                    </div>
                    <div class="col-md-auto">
                        <ui-select
                            id="status"
                            name="status"
                            label="Status"
                            option-label="key"
                            option-value="value"
                            chose-option-text="All"
                            v-model:value="filters.status"
                            :options="_available_filters.status.map(item => ({ key: item, value: item }))"
                        />
                    </div>
                    <div class="col-md-auto">
                        <ui-select
                            id="httpstatus"
                            name="httpstatus"
                            label="HTTP Status"
                            option-label="key"
                            option-value="value"
                            chose-option-text="All"
                            v-model:value="filters.httpstatus"
                            :options="_available_filters.httpstatus.map(item => ({ key: item, value: item }))"
                        />
                    </div>
                    <div class="col-md-auto">
                        <ui-select
                            id="event"
                            name="event"
                            label="Event"
                            option-label="key"
                            option-value="value"
                            chose-option-text="All"
                            v-model:value="filters.event"
                            :options="_available_filters.event.map(item => ({ key: item, value: item }))"
                        />
                    </div>
                </template>
            </div>
            <ui-table class="mb-4" :items="webhooklogs" :fields="fields" :busy="busy" >
                <template #cell(number)="data">
                    <b-button size="sm" @click="data.toggleDetails" class="custum_button">
                        <i v-if="data.detailsShowing" class="fas fa-chevron-down" />
                        <i v-else class="fas fa-chevron-right"></i>
                        {{ data.item.number }}
					</b-button> 
                </template>
                <template #cell(event)="data">
                        {{ data.item.event }}
                </template>
                <template #cell(httpstatus)="data"> 
                    <ui-badge-status-http :httpstatus="data.item.httpstatus">
                        {{ data.item.httpstatus }}
                    </ui-badge-status-http>
                </template>
                <template #cell(status)="data">
                    <ui-status 
                        class="d-inline-flex flex-column align-items-center text-capitalize fs-13px"
                        :status="data.item.status"
                    />
                </template>
                <template #cell(date)="data">
                    {{ $dayjs(data.item.date).format('LL') }} <br />
                    {{ $dayjs(data.item.date).format('LTS') }}
                </template>
                <template #row-details="data">
                    <div>
                        <h6>Event Url</h6>
                        <div>
                            <pre class="formatted_pre_url"> {{ data.item.eventurl }}</pre>
                        </div>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col-md-6">
                                <h6>Request</h6>
                                <pre class="formatted_pre"> {{ data.item.request }}</pre>
                            </div>
                            <div class="col-md-6">
                                <h6>Response</h6>
                                <pre class="formatted_pre"> {{ data.item.response }}</pre>
                            </div>
                        </div>
                    </div>
                </template>
            </ui-table>
            <div class="d-flex justify-content-center">
                <ui-pagination v-model="filters.page" :pages="total_pages" class="mt-3" />
            </div>
        </template>
    </div>
</template>

<script>
import DateRangePicker from 'daterange-picker-vue3';
import 'daterange-picker-vue3/dist/daterange-picker-vue3.css';

import UiButton from '@/components/ui/buttons/Button';
import UiInput from '@/components/ui/Input';
import UiPagination from '@/components/ui/Pagination';
import UiSelect from '@/components/ui/Select';
import UiStatus from '@/components/ui/BadgeStatus';
import UiTable from '@/components/ui/Table.vue';
import UiTableSkeleton from '@/components/ui/skeleton/TableSkeleton.vue';
import UiBadgeStatusHttp from '@/components/ui/BadgeStatusHttp';

import table_mixin from '@/mixins/tables.mixin';


export default {
    components: {
    DateRangePicker,
    UiButton,
    UiInput,
    UiPagination,
    UiSelect,
    UiStatus,
    UiTable,
    UiTableSkeleton,
    UiBadgeStatusHttp
    
},
    mixins: [table_mixin],
    data() {
        const range = {
            startDate: this.$dayjs()
                .subtract(1, 'year')
                .startOf('day')
                .toDate(),
            endDate: this.$dayjs()
                .endOf('day')
                .toDate(),
        };

        return {
            busy: false,
            webhooklogs: null,
            logInfo: null,
            creating: false,
            fields: [
                { key: 'number', label: 'Number' },
                { key: 'event', label: 'Event' },
                { key: 'httpstatus', label: 'HTTP Status' },
                { key: 'status', label: 'Status' },
                { key: 'date', label: 'Date' },
                { key: 'details'}
            ],
            filters: {
                q: '',
                page: 1,
                limit: 50,
                range: { ...range },
                status: null,
                httpstatus: null,
                event: null,
                'created_at[from]': '',
                'created_at[to]': '',
                
            },
            inititialLoading: true,
            loading: false,
            range: { ...range },
            total_pages: null,
            showDetails: false
        };
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this._changeFilters(this.getLogs);
            },
        },
        $route(to) {
            if (to.name === 'webhook-logs') {
                this.filters = this._filtersByQuery(this.filters);
            }
        },
    },
    async mounted() {
        await this._getFilters('webhook-logs');
    },
    computed: {
        formattedJson() {
            return JSON.stringify(this.webhooklogs.request, null, 2);
        }
    },
    methods: {
        changeDate(data) {
            this.filters.range = data;
        },
        async getLogs() {
            try {
                if (this.inititialLoading) {
                    this.loading = true;
                }

                this.busy = true;

                const formattedFilters = JSON.parse(JSON.stringify(this.filters));
                const params = {
                    page: formattedFilters.page,
                    limit: formattedFilters.limit,
                    q: formattedFilters.q,
                    status: formattedFilters.status,
                    httpstatus: formattedFilters.httpstatus,
                    event: formattedFilters.event,
                    'created_at[from]': this.$dayjs(formattedFilters.range.startDate).toISOString(),
                    'created_at[to]': this.$dayjs(formattedFilters.range.endDate).toISOString(),
                };

                this._clearFilters(params);

                if (String(this.filters.q).trim() !== '') {
                    params.q = this.filters.q;
                }

                const { data } = await this.$axios.get('/_/webhook-logs', { params });

                this.total_pages = data.pages;
                this.webhooklogs = data.docs;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                if (this.inititialLoading) {
                    this.loading = false;
                    this.inititialLoading = false
                }

                this.busy = false;
            }
        }
    },
}
</script>

<style lang="scss">
.webhooklogs-list {
    &__date-range-picker {
        align-items: center;
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        gap: 8px;
        height: 40px;
        padding: 4.5px 8px;
        width: 100%;

        .caret {
            margin-left: auto;

            &::before {
                color: $ecart-accent;
                content: '\f078';
                font-size: 16px;
                line-height: 1;
            }
        }
    }

    .vue-daterange-picker {
        width: 100%;
    }

	.custum_button {
		border: none;
		background: none;
		cursor: pointer;
		outline: none;
        color: green;
	}

    .formatted_pre_url {
        background-color: #EBECEF;
        color: black;
        padding: 10px;
        border-radius: 5px;
        overflow-x: auto;  
    }

    .formatted_pre {
        background-color: #253348;
        color: #7698BC;
        padding: 10px;
        border-radius: 5px;
        overflow-x: auto;
        max-height: 300px;
        overflow-y: auto;
    }
}
</style>